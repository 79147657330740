export const colorPalette1 = {
  primary: "#132944",
  diad: "#453313",
  triadic: {
    color1: "#3B450C",
    color2: "#451E1A",
    color3: "#1A5091",
    color4: "#7E9121",
  },
  splitComplementary: {
    color1: "#452817",
    color2: "#917F1A",
    color3: "#915A37",
    color4: "#453D10",
  },
  tetrad: {
    color1: "#174510",
    color2: "#45310C",
    color3: "#451A38",
    color4: "#0C2645",
  },
  hexad: {
    color1: "",
    color2: "",
    color3: "",
    color4: "",
    color6: "",
  },
};

export const colorPalette2 = {
  primary: "#3B7ED1",
  diad: "#85590B",
  triadic: {
    color1: "#3B7ED1",
    color2: "#73851E",
    color3: "#D15B4F",
  },
  tetrad: {
    color1: "#3B7ED1",
    color2: "#269C14",
    color3: "#BD8722",
    color4: "#D14FA9",
  },
};
