import { colorPalette2 } from "src/styles/colorPalette";
import ThemeScale from "src/types/ThemeScale";
interface ThemeColor {
  primary: string;
  secondary: string;
  accent: string;
  background: string;
  backgroundSecondary: string;
  text: string;
  textSecondary: string;
  border: string;
  success: string;
  warning: string;
  error: string;
  titleColor1: string;
  titleColor2: string;
  titleColor3: string;
  titleColor4: string;
}

const lightColors: ThemeColor = {
  primary: "#132944",
  secondary: "#452817",
  accent: "#917F1A",
  background: "#FAFAFA",
  backgroundSecondary: "#EEEEEE",
  text: "#333333",
  textSecondary: "#FAFAFA",
  border: "#CBD4C2",
  success: "#10B981",
  warning: "#F59E0B",
  error: "#EF4444",
  titleColor1: colorPalette2.tetrad.color1,
  titleColor2: colorPalette2.tetrad.color2,
  titleColor3: colorPalette2.tetrad.color3,
  titleColor4: colorPalette2.tetrad.color4,
};

interface Typography {
  fontSize: string;
  fontFamily: { en: string; ja: string };
  fontWeightNormal: number;
  fontWeightBold: number;
}
export const ROOT_FONT_SIZE = 16;
const typography: Typography = {
  fontSize: `${ROOT_FONT_SIZE}px`,
  fontFamily: {
    en: '"Noto Sans"',
    ja: '"Noto Sans JP"',
  },
  fontWeightNormal: 400,
  fontWeightBold: 700,
};

export const breakpoints: ThemeScale = {
  xs: "320px", // smart phone (portrait)
  sm: "576px", // smart phone (landscape)
  md: "768px", // tablet (portrait)
  lg: "992px", // tablet (landscape) and desktop
  xl: "1200px", // desktop (large)
};

const fontSize: ThemeScale = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.5rem",
  xl: "2rem",
  xxl: "3rem",
};

export const lineHeight: ThemeScale = {
  xs: "1rem",
  sm: "1.25rem",
  md: "1.5rem",
  lg: "2rem",
  xl: "3rem",
  xxl: "4.5rem",
};

const spacing: ThemeScale = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "1rem",
  lg: "2rem",
  xl: "4rem",
  xxl: "5rem",
};

const duration: ThemeScale = {
  xs: "0.1s",
  sm: "0.2s",
  md: "0.3s",
  lg: "0.5s",
  xl: "1s",
  xxl: "3s",
};

const border: ThemeScale = {
  xs: "1px",
  sm: "2px",
  md: "3px",
  lg: "4px",
  xl: "5px",
};

const borderRadius: ThemeScale = {
  xs: "0.25rem",
  sm: "0.5rem",
  md: "1rem",
  lg: "2rem",
  xl: "4rem",
};

export interface Theme {
  colors: ThemeColor;
  typography: Typography;
  breakpoints: ThemeScale;
  fontSize: ThemeScale;
  lineHeight: ThemeScale;
  spacing: ThemeScale;
  duration: ThemeScale;
  border: ThemeScale;
  borderRadius: ThemeScale;
}

export const LightTheme: Theme = {
  colors: lightColors,
  typography,
  breakpoints,
  fontSize,
  lineHeight,
  spacing,
  duration,
  border,
  borderRadius,
};
